import { BaseOptions, LocalizedBaseOptions } from "./form.types";
import { FormSpec, SomeInputSpec, localized } from "../../form-specs";
import { FormWidgetSpec, Widget } from "../";
import { getBaseOptionsSpecs, localizedOptions } from "./";
import { getFormFieldVariable, getFormFieldsDescription, isFormFieldRequired } from "./form.util";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { WidgetGroup } from "../widget.enum";
import namespaceList from "../../i18n/namespaceList";
import { readOnlyField } from "../../components/utils";

interface LocalizedWidgetOptions extends LocalizedBaseOptions {
    placeholder: string;
}

interface WidgetOptions extends BaseOptions<LocalizedWidgetOptions> {
    setInputTypePassword: boolean;
    disablePasswordValidation: boolean;
    useAsConfirmPassword: boolean;
    useAsOldPassword: boolean;
    enableMaxCharacterLength?: boolean;
    maxCharacterLength?: number;
    useAsUrlField: boolean;
    enableReadOnly?: boolean;
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "form-text-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginForm, "textWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginForm, "textWidgetOptions"),
    /* jscpd:ignore-start */
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getBaseOptionsSpecs("text"),
                            {
                                variable: "enableMaxCharacterLength",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "enableMaxCharacterLength"),
                                type: "checkbox",
                                default: false,
                                visible: (options: any) => options.useforBookingsModule,
                            },
                            {
                                type: "number",
                                variable: "maxCharacterLength",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "maxCharacterLength"),
                                visible: (options: any) => options.useforBookingsModule && options.enableMaxCharacterLength,
                            },
                            {
                                variable: "useAsUrlField",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "useAsUrlField"),
                                type: "checkbox",
                            },
                            {
                                variable: "setInputTypePassword",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "setInputTypePassword"),
                                type: "checkbox",
                            },
                            readOnlyField("enableReadOnly"),
                            {
                                variable: "disablePasswordValidation",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "disablePasswordValidation"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.setInputTypePassword,
                            },
                            {
                                variable: "useAsConfirmPassword",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "useAsConfirmPassword"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.setInputTypePassword,
                            },
                            {
                                variable: "useAsOldPassword",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "useAsOldPassword"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.setInputTypePassword,
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "placeholder",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "placeholder"),
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
            ],
        },
    ],
    /* jscpd:ignore-end */
};

export const textWidget: FormWidgetSpec<WidgetOptions> = {
    id: "form-text",
    type: "form",
    widgetGroup: WidgetGroup ? WidgetGroup.INPUT : 6,
    name: getI18nLocaleObject(namespaceList.pluginForm, "textWidget"),
    description: getI18nLocaleObject(namespaceList.pluginForm, "textWidgetDesc"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        fieldId: "",
        required: false,
        localized: [],
        dynamicFieldId: "",
        setInputTypePassword: false,
        disablePasswordValidation: false,
        useAsConfirmPassword: false,
        useAsOldPassword: false,
        enableMaxCharacterLength: false,
        useAsUrlField: false,
        enableReadOnly: false,
    }),
    async toInputSpec(widget: Widget<WidgetOptions>, context: CMSProvidedProperties): Promise<SomeInputSpec<any, any>> {
        const { setInputTypePassword, disablePasswordValidation, useAsConfirmPassword, useAsOldPassword, enableMaxCharacterLength, maxCharacterLength, enableReadOnly } = widget.options;
        const localeOptions = localizedOptions(context, widget.options) || { label: "", default: "" };
        const defaultValue = widget.options.useAsUrlField ? window.location.href : localeOptions.default;
        return {
            type: setInputTypePassword ? "password" : "text",
            variable: getFormFieldVariable(widget.options),
            label: localeOptions.label,
            default: defaultValue,
            placeholder: (localeOptions as LocalizedWidgetOptions).placeholder,
            required: await isFormFieldRequired(widget.options, context),
            message: (localeOptions as LocalizedWidgetOptions).message,
            disableValidation: disablePasswordValidation,
            useAsConfirmPassword,
            useAsOldPassword,
            enableMaxCharacterLength,
            maxCharacterLength,
            options: widget.options,
            enableReadOnly,
        };
    },
    instanceDescription({ widget }): string {
        const formFrieldDescriptionParams = {
            /* jscpd:ignore-start */
            fieldId: widget.options.fieldId,
            dynamicFieldId: widget.options.dynamicFieldId,
            useforBookingsModule: widget.options.useforBookingsModule,
            defaultDescription: getI18nLocaleString(namespaceList.pluginForm, "textWidgetDesc"),
            /* jscpd:ignore-end */
        };
        return getFormFieldsDescription(formFrieldDescriptionParams);
    },
};
